// Little hack to provide ':focus' selection
jQuery.extend(jQuery.expr[':'], {
  focus: function(e){ return e == document.activeElement; }
});

var Mobile = {

  init: function()
  {
    this.movers();
    this.controls();
  },

  controls: function()
  {
    var that = this;
    $('body').on('click', '#open-mobile-main', function(e) { that.toggleslide(e, 'main-mobile-open'); });

    // $('body').on('click', '#open-mobile-shortcuts,#close-shortcuts', function(e) { that.toggleslide(e, 'shortcuts-mobile-open'); });
    $('body').on('click', '#open-mobile-shortcuts', function(e) {
      // Sometimes the line between efficiency and
      // the road to Hell seems fuzzy.
      $('#book-new, #book-guest').trigger('click');
    });

    $('body').on('change', '#mobile-venue-change', this.navigate);

    // Leverage existing calendar
    $('body').on('click', '#mobile-date', function() {
      $('#sidebar').show();
      return false;
    });
    $('body').on('click', '#close-main-cal', function() {
      $('#sidebar').hide();
      return false;
    });

    $('body').on('blur', '#date-changer-replace', this.dateactual);
    $('body').on('blur', '#mobile-date-guest', $.proxy(this.editguest, this));

    // $('body').on('keyup', '#guestlist-search', this.guestlistsearch);
    $('body').on('blur', '#reservation-search', this.reservationsearch);

    // Plan on merging these to a general button
    // $('body').on('click', '#clear-guest-search', this.clear_guest_search);
    $('body').on('click', '#clear-res-search', this.clear_res_search);
    $('body').on('click', '#clear-client-search', this.clear_client_search);

    $('body').on('focus', '#reservation-book-form input', this.check_matching);

    //$('body').on('click', '#go-full', this.fullsite);

    $('body').on('poppedcolorbox', '#manager', this.reposition_popup);

    $('body').on('click', '#edit-res-details-btn', this.reposition_popup);
    $('body').on('click', '#cancel-reservation', this.reposition_popup);



    // Hacky stuff follows
    $('body').on('click', 'body.main-mobile-open #manager', function(e) {
      e.preventDefault();
      $('body').removeClass('shortcuts-mobile-open main-mobile-open');
      return false
    });
    $('body').on('blur', '#add-tag-input', function() {
      $('#add-tag-results').hide();
    });
  },

  toggleMobileMain : function() {
	  $('#open-mobile-main').click();
  },

  toggleMobileShortcuts : function() {
	  $('#open-mobile-shortcuts').click();
  },

  reposition_popup: function()
  {
    var offset = $(window).scrollTop() + 50;
    $('#colorbox').css('margin-top', offset);
  },

  check_matching: function()
  {
    var active = $('#input-firstname, #input-lastname, #input-phone').is(':focus');
    var select = $('#client-profile-area').is('.selected');
    if( active || select ) {
      $('#client-profile-area').show();
    } else {
      $('#client-profile-area').hide();
    }
  },

  navigate: function(e)
  {
    var target = $('#mobile-venue-change').val();
    window.location = target;
  },

  fullsite: function(e)
  {
    e.preventDefault()
    var loc = 'https://' + window.location.toString().split('m.')[1];
    window.location = loc;
    return false
  },

  fixer: function()
  {
    var base = $('#base-link-ref').val();
    if( base.indexOf('/a/#') > -1 ) {
      base = base.split('/a/#');
      base = base[0] + base[1];
    }
    return base
  },

  getdate: function()
  {
    var date = $('#mobile-date').val().split('-')
      , _date = new Date(date[0], parseInt(date[1])-1, date[2])
      , fixed = date[1] + '-' + date[2] + '-' + date[0] // "broken =" would be more appropriate
      , display = _date.format('dddd, mmm d');

    $('#mobile-date-display').sext('');
    $('#mobile-date-display').sext(display);

    // Hacky but effective
    var section = 'reservations';
    if( window.location.href.indexOf('guestlist') > -1 ) {
      section = 'guestlist';
    }

    var link = this.fixer() + '/' + section + '/day/' + fixed;
    window.location.href = link;
  },

  editguest: function()
  {
    var date = $('#mobile-date-guest').val().split('-')
      , _date = new Date(date[0], parseInt(date[1])-1, date[2])
      , fixed = date[1] + '-' + date[2] + '-' + date[0];

    var link = this.fixer() + '/home/guestlist/' + fixed + '/create';

    window.location.href = link;
  },

  affixwidth: function(undo)
  {
    var m = $('#manager #content');
    if( undo ) {
      m.css('width', 'auto');
      return;
    }
    var w = m.width();
    m.css('width', w);
  },

  toggleslide: function(e, cls)
  {
    Interface.closeslide('#main-interface, #block-slideout');
    if( $('body').hasClass(cls) ) {
      this.affixwidth(true);
    } else {
      this.affixwidth();
    }
    e.preventDefault();
    $('body').toggleClass(cls);
    return false;
  },

  dateactual: function()
  {
    var date = $('#date-changer-replace').val().split('-')
      , _date = new Date(date[0], parseInt(date[1])-1, date[2])
      , fixed = _date.format('mm/dd/yyyy')
      , display = _date.format('ddd, mmmm d, yyyy');

    $('#actual-date-submit, #actual-date-localized').val(fixed);
    $('#actual-date-display').sext(display);
  },

  /* The burning */
  movers: function()
  {
    $('.mobile-moved').each(function() {
      $(this).appendTo( '#' + $(this).attr('dest_id') );
    });
  },

  reservationsearch: function()
  {
    var search = $(this).val().toLowerCase();
    var objs = $('#booked-box .booked-row');
    objs.hide();
    if( search.length > 2 ) {
      objs.each(function() {
        $o = $(this);
        if( $o.sext().toLowerCase().indexOf(search) > -1 ) {
          $o.show();
        }
      });
    } else {
      objs.show();
    }
  },

  clear_res_search: function()
  {
    $('#reservation-search').val('');
    $('#booked-box .booked-row').show();
  },

  clear_client_search: function()
  {
    $('#search-query-input').val('');
    // Something like this in the future,
    // doubled up functionality with
    // clear search link
    window.location.href = '?oi=2';
  },

  guestlistsearch: function()
  {
    var search = $(this).val().toLowerCase();
    var objs = $('#guestlist-block .guestlist-row');
    objs.hide()
    if( search.length > 2 ) {
      objs.each(function() {
        $o = $(this);
        if( $o.sext().toLowerCase().indexOf(search) > -1 ) {
          $o.show();
        }
      });
    } else {
      objs.show();
    }
  },

  clear_guest_search: function()
  {
    $('#guestlist-search').val('');
    $('#guestlist-block .guestlist-row').show();
  }

};

//var mql = window.matchMedia("only screen and (max-width: 480px)");

$(function() {
  // FastClick.attach(document.body);
  Mobile.init();
});
